import React from "react"
import { graphql } from "gatsby"

import {
  Layout,
  Seo,
  HeaderTextOnly,
  RichText,
  SectionTextWithImage,
} from "../components"
import { PageSectionTextWithImage } from "../types"
import { handleSectionBackground } from "../utils"

interface LegalPageData {
  location: {
    href: string
  }
  data: {
    contentfulPageLegal: {
      heading: string
      updatedAt: string
      pageContent: {
        raw: string
      }
      pageSections: Array<PageSectionTextWithImage>
      seo: {
        title: string
        titleTemplate: {
          extension: string
        }
        description: {
          description: string
        }
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
  }
}

const LegalPage = ({ data, location }: LegalPageData) => {
  const page = data.contentfulPageLegal
  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderTextOnly heading={page.heading} alignment="center">
        <p>Last updated: {page.updatedAt}</p>
      </HeaderTextOnly>
      <section className="wrapper-width wrapper-height wrapper-height-large">
        <RichText content={page?.pageContent} />
      </section>
      {page.pageSections &&
        page.pageSections.length > 0 &&
        page.pageSections.map((section: any, index: number) => (
          <SectionTextWithImage
            key={section.contentful_id}
            contentful_id={section.contentful_id}
            backgroundColour={handleSectionBackground(
              section.backgroundColour,
              index % 2 === 0 ? "grey-lightest" : "white"
            )}
            heading={section.heading}
            content={section.content}
            image={section?.media?.media?.gatsbyImageData}
            imageAlt={section?.media?.media?.title}
            showImageFirst={section.showImageFirst}
            imageBlobBackground={section.imageBlobBackground}
            showSignUpButton={section.showSignUpButton}
            scheduleDemoButton={section.scheduleDemoButton}
            file={section?.media?.media?.file?.url}
            youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
          />
        ))}
    </Layout>
  )
}

export default LegalPage

export const pageQuery = graphql`
  query getLegalPage($id: String!) {
    contentfulPageLegal(contentful_id: { eq: $id }) {
      heading
      updatedAt(fromNow: true)
      pageContent {
        raw
      }
      pageSections {
        contentful_id
        heading
        content {
          raw
        }
        media {
          ... on ContentfulPageSectionTextWithImageImageVideoUploa {
            media {
              title
              gatsbyImageData
              file {
                url
              }
            }
          }
          ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
            youTubeEmbedCode {
              youTubeEmbedCode
            }
          }
        }
        showImageFirst
        imageBlobBackground
      }
      seo {
        title
        titleTemplate {
          extension
        }
        description {
          description
        }
        image {
          fixed {
            src
          }
        }
      }
      pageContent {
        raw
      }
    }
  }
`
